<template>
  <div id="guider">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />导游详情
        </van-col>
        <van-col
          span="14"
          class="header-title"
          @click="onDeleteClick"
          style="text-align: right"
        >
          移除
        </van-col>
      </van-row>
    </div>
    <div class="guider-wrapper">
      <div class="guider-container">
        <!-- <div class="guider-avatar">
          <img :src="guide.avatar" alt="" />
        </div> -->
        <van-image
          class="guider-avatar"
          round
          :src="guide.avatar ? guide.avatar : ''"
          ><template v-slot:error
            ><span style="font-size: 10px">暂无头像</span></template
          ></van-image
        >
        <van-row>
          <!-- <van-col span="24"
            ><div class="star-text">
              <van-icon
                color="#FFAA00"
                v-for="(item, index) in 5"
                :key="index"
                name="star"
              /></div
          ></van-col> -->
          <!-- <van-col span="24"
            ><div class="main-text">{{ guide.name }}</div></van-col
          > -->
          <van-col span="12"
            ><div class="guider-text text-left">姓名：</div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-right">
              {{ guide.name ? guide.name : "暂无" }}
              <span
                v-if="guide.attach_status == '1' || guide.attach_status == '2'"
              >
                <svg-icon :data_iconName="'temp'" :className="''"
              /></span></div
          ></van-col>
          <van-col span="12"
            ><div class="guider-text text-left">性别：</div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-right">
              {{ guide.gender ? guide.gender : "暂无" }}
            </div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-left">身份证：</div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-right">
              {{ guide.id_card ? guide.id_card : "暂无" }}
            </div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-left">联系电话：</div></van-col
          >
          <van-col span="12">
            <div v-if="guide.mobile" class="guider-text text-right">
              <a
                :href="'tel:' + guide.mobile"
                style="
                  color: #436def;
                  text-decoration: underline;
                  font-size: 14px;
                "
                >{{ guide.mobile }}</a
              >
            </div>

            <div v-else class="guider-text text-right">
              {{ "暂无" }}
            </div>
          </van-col>
          <van-col span="12"
            ><div class="guider-text text-left">角色：</div></van-col
          >
          <van-col span="12" class="guider-text text-right">{{guide.role_name}}</van-col>
          <van-col span="12"
            ><div class="guider-text text-left">{{guide.role_name}}证号：</div></van-col
          >

          <van-col span="12"
            ><div class="guider-text text-right">
              <span
                v-if="guide.tourist_card"
                style="color: #436def; text-decoration: underline"
                @click="tourist_card_image_show = true"
                >{{ guide.tourist_card }}</span
              >
               <span v-if="!guide.tourist_card">未完善</span>
            </div>

            </van-col
          >
          <van-col span="12"
            ><div class="guider-text text-left">{{guide.role_name}}资格证：</div></van-col
          >

          <van-col span="12"
            ><div class="guider-text text-right">
              <span
                v-if="guide.tourist_certificate"
                style="color: #436def; text-decoration: underline"
                @click="onImgClick(guide.tourist_certificate_image)"
                >
                  <splitString :value="guide.tourist_certificate"></splitString>
                </span
              >
              <span v-if="!guide.tourist_certificate">未完善</span>
            </div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-left">导游语种：</div></van-col
          >

          <van-col span="12"
            ><div class="guider-text text-right">
              {{ guide.language ? guide.language : "暂无" }}
            </div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-left">导游级别：</div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-right">
              {{ guide.level ? guide.level : "暂无" }}
            </div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-left">出团次数：</div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-right">
              {{
                guide.travel_lists_count
                  ? guide.travel_lists_count + "次"
                  : "0次"
              }}
            </div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-left">评分：</div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-right">
            <van-rate
                v-model="guide.rate_star"
                :size="25"
                color="#FFAA00"
                void-icon="star"
                void-color="#eee"
                readonly
                allow-half
              />
            </div></van-col
          >
          <!-- <van-col span="24">
          
          </van-col> -->
        </van-row>
        <van-cell
          title="查看借调旅行社"
          is-link
          :to="'/guider/temp_agency?id=' + $route.query.id"
          style="padding: 0; margin-top: 5px; color: #666666"
        />
      </div>
      <div
        class="message-tuan"
        v-for="(item, index) in guide.travel_lists"
        :key="index"
        @click="onItemClick(item)"
      >
        <van-row class="tuan-body-header">
          <van-col span="6">
            <van-image class="item-avatar" round :src="item.image_url" />
          </van-col>
          <van-col span="18">
            <div class="item-name">
              {{ item.list_name }}
            </div>
            <div class="item-sub-text">电话：{{ item.guide_mobile }}</div>
            <div class="item-sub-text">导游：{{ item.guide }}</div>
          </van-col>
        </van-row>
        <van-row class="tuan-info">
          <van-col span="10">
            <div class="tuan-start-time text-left">{{ item.start_date }}</div>
            <div class="tuan-start-address text-left">
              {{ item.start_addr ? item.start_addr : "暂无" }}
            </div>
          </van-col>
          <van-col span="4">
            <div class="need-time">{{ item.days ? item.days : "0" }}天</div>
            <div class="tuan-items" @click.stop="onShowClick(item)">
              行程景点
            </div>
          </van-col>
          <van-col span="10">
            <div class="tuan-end-time text-right">{{ item.end_date }}</div>
            <div class="tuan-end-address text-right">
              {{ item.end_addr ? item.end_addr : "暂无" }}
            </div>
          </van-col>
        </van-row>
        <div class="start-date">出发日期：{{ item.start_date }}</div>
        <div class="tuan-ren-number">
          团队人数：{{ item.tourists_count ? item.tourists_count : 0 }}人
        </div>
        <div :class="[stateClass[item.status]]">{{ item.status_text }}</div>
      </div>
    </div>
    <van-overlay z-index="100" :show="show" @click="show = false">
      <div class="wrapper">
        <h6 class="text-center wrpper-title-large">行程景点</h6>
        <van-row style="margin-top: 20px">
          <van-col span="3" class="wrpper-title text-left">景点</van-col>
          <van-col span="7" class="wrpper-title text-center">到达</van-col>
          <van-col span="7" class="wrpper-title text-center">出发</van-col>
          <van-col span="7" class="wrpper-title text-right">逗留</van-col>
        </van-row>
        <van-row
          style="margin-top: 10px"
          v-for="(item, index) in nodes"
          :key="index"
        >
          <van-col span="3" class="wrpper-text text-left van-ellipsis">{{
            item.node_name
          }}</van-col>
          <van-col span="7" class="wrpper-text text-center van-ellipsis">{{
            item.start_time ? item.start_time.slice(5, 16) : "--"
          }}</van-col>
          <van-col span="7" class="wrpper-text text-center van-ellipsis">{{
            item.end_time ? item.end_time.slice(5, 16) : "--"
          }}</van-col>
          <van-col span="7" class="wrpper-text text-right van-ellipsis">{{
            item.stay_time ? item.stay_time : "--"
          }}</van-col>
        </van-row>
        <van-empty
          image="error"
          v-if="nodes.length == 0"
          description="暂无景点"
        />
      </div>
    </van-overlay>
    <van-popup v-model="tourist_certificate_image_show">
      <van-empty
        image="error"
        description="未完善"
      />
    </van-popup>
    <van-popup v-model="tourist_card_image_show">
      <van-image
        v-if="guide.tourist_card_image"
        :src="guide.tourist_card_image"
      >
        <template v-slot:error>未完善</template>
      </van-image>
      <van-empty
        v-if="!guide.tourist_card_image"
        image="error"
        description="未完善"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import vueQr from "vue-qr";
import splitString from "@/views/components/SplitString"
import {ImagePreview} from "vant"
export default {
  components: { vueQr, splitString },
  name: "guider-item",
  data() {
    const _self = this;
    return {
      tourist_certificate_image_show: false,
      tourist_card_image_show: false,
      guide: {},
      show: false,
      nodes: [],
      stateClass: [
        "status-btn-plan",
        "status-btn-ing",
        "status-btn-completed",
        "status-btn-cancel",
      ],
      downloadData: {
        url: "https://www.baidu.com",
        icon: "",
      },
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async get_travel_list(id) {
      const res = await this.$apis.get_travel_list(id);
      this.nodes = res.data.travel_list_nodes;
      this.show = true;
    },
    onShowClick(item) {
      this.get_travel_list(item.id);
    },
    onItemClick(item) {
      this.$router.push({
        path: "/tuan/item",
        query: {
          id: item.id,
        },
      });
    },
    onDeleteClick() {
      const _self = this;
      Dialog.confirm({
        // title: "标题",
        message: "确定移除该导游吗?",
      })
        .then(() => {
          // on confirm
          if (_self.$route.query.relation_id) {
            _self.remove_guide_from_tmp_relation(
              _self.$route.query.relation_id
            );
          } else {
            _self.remove_guide_from_company();
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    async remove_guide_from_company() {
      const id = this.$route.query.id;
      const res = await this.$apis.remove_guide_from_company(id);
      if (res.status == 200) {
        Toast("移除成功");
        this.$router.go(-1);
      }
    },
    async get_guide(id) {
      const res = await this.$apis.get_guide(id);
      this.guide = res.data;
    },
    async get_tmp_guide(id) {
      const res = await this.$apis.get_tmp_guide(id);
      this.guide = res.data;
    },
    async remove_guide_from_tmp_relation(id) {
      const res = await this.$apis.remove_guide_from_tmp_relation(id);
      if (res.status == 200) {
        Toast("移除成功");
        this.$router.go(-1);
      }
    },
    onImgClick(url) {
      if(url == '') {
        this.tourist_certificate_image_show = true
      } else {
        ImagePreview(url.split(','))
      }
    }
  },
  created() {
    const id = this.$route.query.id;
    const relation_id = this.$route.query.relation_id;
    if (!id) {
      Toast("没有id");
      return false;
    }
    if (relation_id) {
      this.get_tmp_guide(id);
    } else {
      this.get_guide(id);
    }
  },
};
</script>

<style scoped>
#guider {
  height: 100vh;
  /* overflow-y: scroll;
  padding-bottom: 2rem; */
  padding-top: 10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.guider-wrapper {
  padding: 0.4rem;
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 4rem;
  /* margin-top:2vh; */
}
.guider-container {
  padding: 0.4rem;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
  margin-top: 0.75rem;
}
.guider-avatar {
  position: absolute;
  top: -0.75rem;
  left: 50%;
  transform: translateX(-50%);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  /* z-index: 1000; */
}
.guider-avatar img {
  width: 100%;
  height: 100%;
  /* z-index: 100; */
}

.guider-text {
  font-size: 14px;
  color: #666666;
  margin-top: 0.2rem;
  text-align: center;
}
.main-text {
  font-size: 16px;
  color: #333333;
  text-align: center;
  font-weight: bold;
}
.star-text {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
}
.agency-a {
  color: #1989fa;
}
.sub-text {
  font-size: 14px;
  color: #666666;
  margin-top: 5px;
  text-align: center;
}
.qr-container {
  margin-top: 20px;
}
.qr-img {
  background: #ebebeb;
  border-radius: 28px;
  border-radius: 28px;
  width: 160px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.message-tuan {
  background: #fff;
  padding: 0.4rem;
  border-radius: 20px;
  position: relative;
  margin-top: 10px;
}
.tuan-body-header {
  background: #ffff;
  border-radius: 20px;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  z-index: 10;
  height: 1.4rem;
  border-bottom: 1px dashed #f3f3f3;
}
.tuan-body-header .item-avatar {
  overflow: hidden;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
}
.tuan-body-header .item-avatar img {
  height: 100%;
  height: 100%;
}
.tuan-body-header .item-name {
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.tuan-body-header .item-sub-text {
  color: #666;
  font-size: 12px;
}
.tuan-body-header .item-agency-text {
  font-size: 14px;
  color: #666;
}
.tuan-start-time,
.tuan-end-time {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.tuan-start-address,
.tuan-end-address {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.tuan-items {
  font-size: 12px;
  color: #436def;
  text-align: center;
}
.need-time {
  font-size: 12px;
  color: #666666;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}
.start-date {
  font-size: 12px;
  color: #666666;
  margin-top: 20px;
}
.tuan-ren-number {
  font-size: 12px;
  color: #666666;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.status-btn-cancel {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  background: #6f6f6f23;
  color: #606060;
  padding: 2px 0.2rem;
}
.status-btn-completed {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  background: #13C2C212;
  color: #13C2C2;;
  padding: 2px 0.2rem;
}
.status-btn-plan {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  color: #4168EE;
  background: #4168EE12;;
  padding: 2px 0.2rem;
}
.status-btn-ing {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  color: #fff;
  background: #4168EE;
  padding: 2px 0.2rem;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.wrapper {
  width: 90vw;
  margin: 0 auto;
  background: #fff;
  border-radius: 20px;
  border-radius: 20px;
  height: 60vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0.3rem;
  overflow-y: scroll;
}
.wrpper-title {
  color: #333;
  font-size: 12px;
  font-weight: bold;
}
.wrpper-text {
  color: #666;
  font-size: 12px;
}
</style>
